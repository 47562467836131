import { createMemo, Match, Show, Switch } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import type { ParagraphPush } from "~/types/drupal_jsonapi";

import "./ParagraphProgramPush.css";

import IconAlarm from "~/img/icons/push/alarm.svg";
import IconFeedback from "~/img/icons/push/feedback.svg";
import IconBookmark from "~/img/icons/push/bookmark.svg";
import IconForum from "~/img/icons/push/forum.svg";
import IconGrade from "~/img/icons/push/grade.svg";
import IconStars from "~/img/icons/push/stars.svg";
import IconEvent from "~/img/icons/push/event.svg";
import IconPieChart from "~/img/icons/push/pie_chart.svg";
import IconInsertChart from "~/img/icons/push/insert_chart.svg";
import IconInsertChartOutlines from "~/img/icons/push/insert_chart_outlined.svg";
import IconSupervisedUserCircle from "~/img/icons/push/supervised_user_circle.svg";
import IconSupervisorAccount from "~/img/icons/push/supervisor_account.svg";
import IconGroups from "~/img/icons/push/groups.svg";
import IconSchedule from "~/img/icons/push/schedule.svg";
import IconInfo from "~/img/icons/push/info.svg";
import IconAccountBox from "~/img/icons/push/account_box.svg";
import IconAccountCircle from "~/img/icons/push/account_circle.svg";
import IconPersonPin from "~/img/icons/push/person_pin.svg";
import IconPerson from "~/img/icons/push/person.svg";
import IconFace from "~/img/icons/push/face.svg";
import IconContactSupport from "~/img/icons/push/contact_support.svg";
import IconHelp from "~/img/icons/push/help.svg";
import IconHelpOutline from "~/img/icons/push/help_outline.svg";
import IconSearch from "~/img/icons/push/search.svg";
import IconLiveTv from "~/img/icons/push/live_tv.svg";
import IconDuo from "~/img/icons/push/duo.svg";
import { gotoUrlWithUtm } from "~/utils/url";
import { getFormEventNameFromCrmObject } from "./ProgramFormInPage";

type ParagraphPushProps = {
  wrapper: ProgramWrapper;
  push: ParagraphPush;
};

export default function ParagraphProgramPush(props: ParagraphPushProps) {
  const [, { sendClick }] = useEventsContext();
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  const remainingDaysCount = createMemo(() => {
    if (!props.push.field_countdown_stop) {
      return 0;
    }

    const now = new Date();
    const date = new Date(props.push.field_countdown_stop);

    if (!date) {
      return 0;
    }

    const diff = date.getTime() - now.getTime();
    const days = Math.floor(diff / (60 * 60 * 24 * 1000));
    return days > 0 ? days : 0;
  });

  return (
    <>
      <Show when={props.push.field_disp}>
        <div
          class="paragraph-push"
          style={{
            background: props.push.field_bg_color ?? props.push.field_bg_color,
            color: props.push.field_text_color ?? props.push.field_text_color,
          }}
        >
          <div
            class="outer"
            style={{
              background:
                props.push.field_bg_color ?? props.push.field_bg_color,
            }}
          />
          <div class="inner">
            <Show
              when={props.push.field_countdown_disp && remainingDaysCount() > 0}
            >
              <span class="push-count" data-test="countdown">
                <span>J-{remainingDaysCount()}</span>
              </span>
            </Show>
            <Show when={props.push.field_icon}>
              <i aria-hidden="true" class="cog-icon" data-test="icon">
                <Switch>
                  <Match when={props.push.field_icon === "alarm"}>
                    <IconAlarm />
                  </Match>
                  <Match when={props.push.field_icon === "announcement"}>
                    <IconFeedback />
                  </Match>
                  <Match when={props.push.field_icon === "bookmark_border"}>
                    <IconBookmark />
                  </Match>
                  <Match when={props.push.field_icon === "forum"}>
                    <IconForum />
                  </Match>
                  <Match when={props.push.field_icon === "grade"}>
                    <IconGrade />
                  </Match>
                  <Match when={props.push.field_icon === "stars"}>
                    <IconStars />
                  </Match>
                  <Match when={props.push.field_icon === "event"}>
                    <IconEvent />
                  </Match>
                  <Match when={props.push.field_icon === "pie_chart"}>
                    <IconPieChart />
                  </Match>
                  <Match when={props.push.field_icon === "insert_chart"}>
                    <IconInsertChart />
                  </Match>
                  <Match
                    when={props.push.field_icon === "insert_chart_outlined"}
                  >
                    <IconInsertChartOutlines />
                  </Match>
                  <Match
                    when={props.push.field_icon === "supervised_user_circle"}
                  >
                    <IconSupervisedUserCircle />
                  </Match>
                  <Match when={props.push.field_icon === "supervisor_account"}>
                    <IconSupervisorAccount />
                  </Match>
                  <Match when={props.push.field_icon === "groups"}>
                    <IconGroups />
                  </Match>
                  <Match when={props.push.field_icon === "schedule"}>
                    <IconSchedule />
                  </Match>
                  <Match when={props.push.field_icon === "info"}>
                    <IconInfo />
                  </Match>
                  <Match when={props.push.field_icon === "account_box"}>
                    <IconAccountBox />
                  </Match>
                  <Match when={props.push.field_icon === "account_circle"}>
                    <IconAccountCircle />
                  </Match>
                  <Match when={props.push.field_icon === "person_pin"}>
                    <IconPersonPin />
                  </Match>
                  <Match when={props.push.field_icon === "perm_identity"}>
                    <IconPerson />
                  </Match>
                  <Match when={props.push.field_icon === "face"}>
                    <IconFace />
                  </Match>
                  <Match when={props.push.field_icon === "contact_support"}>
                    <IconContactSupport />
                  </Match>
                  <Match when={props.push.field_icon === "help"}>
                    <IconHelp />
                  </Match>
                  <Match when={props.push.field_icon === "help_outline"}>
                    <IconHelpOutline />
                  </Match>
                  <Match when={props.push.field_icon === "search"}>
                    <IconSearch />
                  </Match>
                  <Match when={props.push.field_icon === "ondemand_video"}>
                    <IconLiveTv />
                  </Match>
                  <Match when={props.push.field_icon === "duo"}>
                    <IconDuo />
                  </Match>
                </Switch>
              </i>
            </Show>
            <div class="push-text">
              <div class="the-text" data-test="body">
                <Show when={props.push.field_title}>
                  <h3>{props.push.field_title}</h3>
                </Show>
                <Show when={props.push.field_description}>
                  <p>{props.push.field_description}</p>
                </Show>
              </div>
              <Show when={props.push.field_button_text}>
                <Show
                  fallback={
                    <button
                      type="button"
                      class="btn"
                      data-test="btn"
                      onClick={() => {
                        sendClick("btn-push-program", "first-screen");
                        gotoUrlWithUtm(props.push.field_button_url!);
                      }}
                    >
                      {props.push.field_button_text}
                    </button>
                  }
                  when={props.push.field_form_disp}
                >
                  <button
                    type="button"
                    class="btn"
                    data-test="cta-in-page"
                    onClick={(e) => {
                      sendShowEvent(
                        getFormEventNameFromCrmObject(
                          props.wrapper.program.field_form.field_crm_object,
                        ),
                        e,
                        {
                          nid: props.wrapper.program.drupal_internal__nid,
                        },
                      );
                      openModalForm!("in-page", {
                        wrapper: props.wrapper,
                        formConfig: props.wrapper.program.field_form,
                      });
                      setModalFormHeadline!(
                        props.wrapper.program.field_form.field_title,
                      );
                    }}
                  >
                    {props.push.field_button_text}
                  </button>
                </Show>
              </Show>
            </div>
          </div>
        </div>
      </Show>
    </>
  );
}
