import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./CityscanBlock.css";

type CityscanBlockProps = {
  wrapper: ProgramWrapper;
};

export default function CityscanBlock(props: CityscanBlockProps) {
  const settingsContext = useDrupalSettingsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="cityscan-block" data-test="block-neighborhood">
        <picture>
          <source
            media="(min-width: 375px)"
            srcset={imgProxy(
              "/images/cityscan-visual.jpg",
              "size:770:467/quality:90/resizing_type:fill",
            )}
          />
          <img
            src={imgProxy(
              "/images/cityscan-visual.jpg",
              `size:327:198/quality:90/resizing_type:fill`,
            )}
            alt=""
            height="198"
            width="327"
            loading="lazy"
          />
        </picture>
        <div class="cityscan-content">
          <span class="new">Nouveau</span>
          <h2>Découvrez votre futur quartier</h2>
          <p>
            Accédez en un clic à toutes les informations utiles de votre
            quartier&nbsp;: localisation des transports, des commerces, des
            écoles, des espaces verts et culturels. Vous pouvez également
            anticiper vos temps de trajets, mesurer la qualité de l'air, ou le
            débit internet et bien plus encore&nbsp;!
          </p>
          <button
            type="button"
            class="btn"
            onClick={() => {
              setMediaOverlay(
                "embed",
                `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
              );
            }}
            data-test="btn-map"
          >
            Voir le quartier
          </button>
        </div>
      </section>
    </>
  );
}
