import Anchors from "~/components/Program/Components/Anchors";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./Tabs.css";

type TabsProps = {
  wrapper: ProgramWrapper;
};

export default function Tabs(props: TabsProps) {
  return (
    <>
      <section class="program-tabs" data-test="tabs">
        <Anchors wrapper={props.wrapper} withZoneAndTest={true} />
      </section>
    </>
  );
}
