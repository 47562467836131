import type { ProgramWrapper } from "~/utils/program_wrapper";
import { geographyBreadcrumbItems } from "~/utils/helper_program";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import {
  createEffect,
  createMemo,
  createRenderEffect,
  DEV,
  Show,
  Suspense,
} from "solid-js";
import { urlRs } from "~/utils/url";
import FirstScreen from "~/components/Program/Components/FirstScreen";
import Presentation from "~/components/Program/Components/Presentation";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { hasNoEscape } from "~/utils/no_escape";
import ProgramsNearbyOutOfStock from "~/components/Program/Components/ProgramsNearbyOutOfStock";
import { useLotActive } from "~/contexts/LotActiveContext";
import LotDrawer from "~/components/Program/Components/LotDrawer";

import "./ProgramCommon.css";
import { useEventsContext } from "~/contexts/EventsContext";
import * as helper from "~/utils/helper_program";
import GtmVars from "../shared/Trackers/GtmVars";
import { stripTags } from "~/utils/format";

type ProgramProps = {
  wrapper: ProgramWrapper;
};

export function ProgramOutOfStock(props: ProgramProps) {
  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("search", "/programme-immobilier-neuf/"),
      text: "Programmes immobiliers neufs",
    };
    items.push(root);

    const geographiesTemp: BreadcrumbItem[] = [];
    const geographies: BreadcrumbItem[] = geographyBreadcrumbItems(
      props.wrapper.program.field_geography,
      geographiesTemp,
    ).toReversed();

    geographies.forEach((geography: BreadcrumbItem) => {
      items.push(geography);
    });

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.wrapper.program?.title,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const [lotProvider] = useLotActive();

  if (DEV) {
    createEffect(() => {
      if (lotProvider.lot) {
        console.log("program current ID:", lotProvider.wrapper?.program.id);
        console.log("Lot current ID:", lotProvider.lot.id);
      }
    });
  }

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    // By default, template is set to "program-std"
    if (helper.isOutOfStock(props.wrapper)) {
      setTemplate("program-out-of-stock");
    }
  });

  // Get program URL from meta tag
  const programUrl = () =>
    props.wrapper.program.metatag.find(
      (tag) => tag.attributes.rel === "canonical",
    )?.attributes.href;

  const photoUrl = () => {
    if (props.wrapper.program.field_image_desktop?.length) {
      const domain = programUrl()?.split("/")[2];

      return `https://${domain}${props.wrapper.program.field_image_desktop!.at(0)!.uri.url}`;
    }
    return "";
  };

  const gtmValues = () => {
    return {
      "all.pageType": "product page",
      "all.mainCategory": breadcrumbItems()[0].text,
      "all.category": breadcrumbItems()[1]?.text ?? "",
      "all.subCategory": breadcrumbItems()[2]?.text ?? "",
      "all.subsubCategory": breadcrumbItems()[3]?.text ?? "",
      "all.subsubsubCategory": breadcrumbItems()[4]?.text ?? "",
      "produit.name": props.wrapper.program.title,
      "produit.id": props.wrapper.program.drupal_internal__nid + "",
      "produit.id_pp": props.wrapper.program.field_program_id ?? "",
      "produit.url": programUrl() ?? "",
      "produit.codePostal": props.wrapper.program.field_postal_code ?? "",
      "produit.region": breadcrumbItems()[1]?.text ?? "",
      "produit.departement": breadcrumbItems()[2]?.text ?? "",
      "produit.ville": breadcrumbItems()[3]?.text ?? "",
      "produit.adresse": `${props.wrapper.program.field_so_street} ${props.wrapper.program.field_so_postal_code} ${props.wrapper.program.field_so_city}`,
      "produit.livraison": stripTags(helper.deliveryTrimester(props.wrapper)!),
      "produit.photo": photoUrl(),
      "produit.type": props.wrapper.program.field_sales_state?.name ?? "",
      idLot: [],
      template:
        breadcrumbItems()[1]?.text === "Île-de-France"
          ? "program-std-idf"
          : "program-std-other",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="node-program">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>

        <FirstScreen wrapper={props.wrapper} />

        <div class="program-sections">
          <div class="content-part">
            <Presentation wrapper={props.wrapper} />
          </div>
        </div>

        <Show
          when={
            !hasNoEscape() &&
            props.wrapper.program.other_programs_geo_level_1.length > 0
          }
        >
          <ProgramsNearbyOutOfStock wrapper={props.wrapper} />
        </Show>
      </article>
      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}
