import FirstScreenMainImage from "~/components/Program/Components/FirstScreenMainImage";
import {
  Show,
  createResource,
  createEffect,
  createSignal,
  Suspense,
} from "solid-js";
import ParagraphProgramPush from "~/components/Program/Components/ParagraphProgramPush";
import * as helper from "~/utils/helper_program";
import MediaContainer from "~/components/Program/Components/MediaContainer";
import { hasNoEscape } from "~/utils/no_escape";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { getProgramViewCount } from "~/lib/fetcher";
import {
  getPricesSummary,
  getTypologiesSummary,
  isOutOfStock,
} from "~/utils/helper_program";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import IconPlayCircle from "~/img/icons/play_circle.svg";
import IconArrowBack from "~/img/icons/arrow_back.svg";
import IconChevronRight from "~/img/icons/chevron_right.svg";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./FirstScreen.css";
import NouveauNeufLogos from "~/components/shared/NouveauNeufLogos";

type FirstScreenProps = {
  wrapper: ProgramWrapper;
};

export default function FirstScreen(props: FirstScreenProps) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();

  return (
    <>
      <section
        class="full-visual"
        data-ga-zone="first-screen"
        data-test="first-screen"
      >
        <div class="visual">
          <FirstScreenMainImage
            imageMobile={props.wrapper.program.field_image_mobile}
            imageDesktop={props.wrapper.program.field_image_desktop}
          />
          <Show when={hasNoEscape()}>
            <span class="cog-logo">
              <img
                src="/images/logo-cogedim.svg"
                alt="Logo Cogedim"
                height="40"
                width="40"
              />
              Cogedim
            </span>
          </Show>

          <Show when={!isOutOfStock(props.wrapper)}>
            <Show
              when={props.wrapper.program.field_video_first_screen}
              fallback={
                <Show
                  when={
                    props.wrapper.program.field_image_desktop &&
                    props.wrapper.program.field_image_desktop!.length > 0
                  }
                >
                  <button
                    type="button"
                    onClick={() => {
                      sendClick("main-image", "first-screen");
                      setMediaOverlay(
                        "images",
                        props.wrapper.program.field_image_desktop,
                      );
                    }}
                    class="direct-open-gallery"
                    aria-label="Afficher la galerie"
                    data-test="btn-gallery"
                  />
                </Show>
              }
            >
              <button
                type="button"
                onClick={() => {
                  sendClick("btn-videos", "first-screen");
                  setMediaOverlay("video", props.wrapper.program.field_video);
                }}
                class="direct-open-gallery video"
                data-test="btn-video"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconPlayCircle />
                </i>
                <span>Lancer la vidéo</span>
              </button>
            </Show>
          </Show>

          <div class="tools">
            <Show when={!hasNoEscape()}>
              <button
                type="button"
                class="btn btn-icon back-link"
                onClick={() => window.history.back()}
                aria-label="Retour"
                data-test="btn-back"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconArrowBack />
                </i>
              </button>
            </Show>
          </div>
        </div>

        <div class="info-box">
          <div
            id="info-box-content"
            class="content-part info-box-content"
            classList={{
              "out-of-stock": isOutOfStock(props.wrapper),
              "with-nouveau-neuf":
                settings.nouveau_neuf_enabled &&
                props.wrapper.program.field_segments_mkg &&
                props.wrapper.program.field_segments_mkg?.length > 0,
            }}
          >
            <Show
              when={isOutOfStock(props.wrapper)}
              fallback={<ProgramStdContent wrapper={props.wrapper} />}
            >
              <div data-test="header">
                <CommonContent wrapper={props.wrapper} />
                <h1>{props.wrapper.program.title}</h1>
                <p class="out-of-stock-text">
                  Il n'y a plus de logement disponible.
                </p>
              </div>
            </Show>
          </div>
        </div>
      </section>
    </>
  );
}

function ProgramStdContent(props: { wrapper: ProgramWrapper }) {
  const [, { sendShowEvent, setTempZone }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [id, setId] = createSignal<number>(
    // eslint-disable-next-line solid/reactivity
    props.wrapper.program.drupal_internal__nid,
  );
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();

  const [viewCount] = createResource(id, getProgramViewCount, {
    deferStream: false,
    ssrLoadFrom: "server",
  });

  createEffect(() => {
    setId(props.wrapper.program.drupal_internal__nid);
  });

  return (
    <>
      <Show when={props.wrapper.program.field_push_top}>
        <div data-test="push-top">
          <ParagraphProgramPush
            wrapper={props.wrapper}
            push={props.wrapper.program.field_push_top}
          />
        </div>
      </Show>

      <div class="row" data-test="header">
        <div class="left-part">
          <Show
            when={props.wrapper.program.animation}
            fallback={
              <Suspense>
                <Show when={viewCount()}>
                  <div class="view-count" data-test="counter">
                    <strong>{viewCount()} visiteurs</strong> ont consulté cette
                    résidence sur les 30 derniers jours.
                  </div>
                </Show>
              </Suspense>
            }
          >
            <a
              href="#exclusive-offer"
              class="exclusive-offer"
              data-test="offer"
            >
              Offre exclusive{" "}
              <i aria-hidden="true" class="cog-icon">
                <IconChevronRight />
              </i>
            </a>
          </Show>

          <CommonContent wrapper={props.wrapper} />
          <h1>{props.wrapper.program.title}</h1>
          <Show when={!helper.isPreview(props.wrapper)}>
            <div class="summary" data-test="summary">
              <span innerHTML={getTypologiesSummary(props.wrapper)} />{" "}
              <span class="price" innerHTML={getPricesSummary(props.wrapper)} />
            </div>
          </Show>

          <MediaContainer wrapper={props.wrapper} />

          <div class="ctas">
            <Show when={props.wrapper.program.field_leaflet?.uri?.url}>
              <Show
                when={settings.form_leaflet_bypass}
                fallback={
                  <button
                    type="button"
                    class="btn"
                    data-test="cta-leaflet"
                    onClick={(e) => {
                      sendShowEvent(`leaflet`, e, {
                        nid: props.wrapper.program.drupal_internal__nid,
                      });
                      openModalForm!("leaflet", { wrapper: props.wrapper });
                      setModalFormHeadline!("Télécharger la brochure");
                    }}
                  >
                    Télécharger la brochure
                  </button>
                }
              >
                <a
                  href={props.wrapper.program.field_leaflet?.uri?.url}
                  class="btn"
                  data-test="btn-leaflet"
                  target="_blank"
                  onClick={() => {
                    sendClick(`btn-leaflet`, "first-screen");
                  }}
                  rel="noindex nofollow"
                >
                  Télécharger la brochure
                </a>
              </Show>
            </Show>
            <button
              type="button"
              class="btn"
              data-test="cta-advisor"
              onClick={(e) => {
                setTempZone(e);
                openModalForm!("advisor", { wrapper: props.wrapper });
                setModalFormHeadline!("Contacter un conseiller");
              }}
            >
              Contacter un conseiller
            </button>
          </div>
        </div>
        <Show
          when={
            settings.nouveau_neuf_enabled &&
            props.wrapper.program.field_segments_mkg &&
            props.wrapper.program.field_segments_mkg?.length > 0
          }
        >
          <NouveauNeufLogos
            zone="program"
            segmentsMkg={props.wrapper.program.field_segments_mkg!}
          />
        </Show>
      </div>
    </>
  );
}

function CommonContent(props: { wrapper: ProgramWrapper }) {
  return (
    <>
      <address itemscope itemtype="https://schema.org/PostalAddress">
        <Show
          fallback={
            <Show
              fallback={
                <a href={props.wrapper.program.field_geography?.path?.alias}>
                  <span itemProp="addressLocality">
                    {props.wrapper.program.field_city}
                  </span>{" "}
                  -{" "}
                  <span itemProp="postalCode">
                    {props.wrapper.program.field_postal_code?.substring(0, 2)}
                  </span>
                </a>
              }
              when={hasNoEscape()}
            >
              <span itemprop="addressLocality">
                {props.wrapper.program.field_city}
              </span>{" "}
              -{" "}
              <span itemprop="postalCode">
                {props.wrapper.program.field_postal_code?.substring(0, 2)}
              </span>
            </Show>
          }
          when={props.wrapper.program.field_address_hide}
        >
          <Show when={props.wrapper.program.field_address_override}>
            <span itemProp="addressLocality">
              {props.wrapper.program.field_address_override}
            </span>
          </Show>
        </Show>
      </address>
    </>
  );
}
