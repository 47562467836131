import { createMemo } from "solid-js";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import imgProxy from "~/utils/imgproxy";

import type { ImageField } from "~/types/drupal_jsonapi";

type FirstScreenMainImageProps = {
  imageMobile?: ImageField;
  imageDesktop?: ImageField[];
};

export default function FirstScreenMainImage(props: FirstScreenMainImageProps) {
  const [viewPortState] = useViewportSizeContext();

  const imageAlt = createMemo(() => {
    if (viewPortState.viewPortIsMoreThan768 && props.imageDesktop!.length > 0) {
      return props.imageDesktop!.at(0)!.meta?.alt;
    }
    if (!viewPortState.viewPortIsMoreThan768 && props.imageMobile) {
      return props.imageMobile?.meta?.alt;
    }
    return undefined;
  });

  const defaultImg = createMemo(() => {
    if (viewPortState.viewPortIsMoreThan768) {
      if (props.imageDesktop && props.imageDesktop.length > 0) {
        return props.imageDesktop.at(0)!.uri.url;
      } else {
        return "/images/default-program-visual-desktop.png";
      }
    } else {
      if (props.imageMobile) {
        return props.imageMobile.uri.url;
      } else {
        return "/images/default-program-visual-mobile.png";
      }
    }
  });

  return (
    <>
      <picture>
        <source
          media="(min-width: 1367px)"
          srcset={imgProxy(
            props.imageDesktop
              ? props.imageDesktop.at(0)!.uri.url
              : "/images/default-program-visual-desktop.png",
            "size:1920:1080/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 1025px) and (max-width: 1366px)"
          srcset={imgProxy(
            props.imageDesktop
              ? props.imageDesktop.at(0)!.uri.url
              : "/images/default-program-visual-desktop.png",
            "size:1366:768/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 768px) and (max-width: 1024px)"
          srcset={imgProxy(
            props.imageDesktop
              ? props.imageDesktop.at(0)!.uri.url
              : "/images/default-program-visual-desktop.png",
            "size:1024:576/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 376px)"
          srcset={imgProxy(
            props.imageMobile
              ? props.imageMobile.uri.url
              : "/images/default-program-visual-mobile.png",
            "size:750:1334/resizing_type:fill",
          )}
        />
        <source
          media="(max-width: 375px)"
          srcset={imgProxy(
            props.imageMobile
              ? props.imageMobile.uri.url
              : "/images/default-program-visual-mobile.png",
            "size:375:667/resizing_type:fill",
          )}
        />
        <img
          fetchpriority="high"
          src={imgProxy(
            defaultImg()!,
            `size:${viewPortState.viewPortIsMoreThan768 ? "1366:768" : "375:667"}/resizing_type:fill`,
          )}
          alt={imageAlt()}
          height={viewPortState.viewPortIsMoreThan768 ? 768 : 667}
          width={viewPortState.viewPortIsMoreThan768 ? 1366 : 375}
          loading="lazy"
        />
      </picture>
    </>
  );
}
