import { createSignal, Show, Suspense } from "solid-js";
import OtherCities from "~/components/Program/Components/OtherCities";
import ProgramsNearbyList from "~/components/Program/Components/ProgramsNearbyList";
import { clientOnly } from "@solidjs/start";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./ProgramsNearby.css";
import { ProjectErrorBoundary } from "~/components/shared/ProjectErrorBoundary";

const ProgramsNearbyMap = clientOnly(() => import("./ProgramsNearbyMap"));

export default function ProgramsNearbyOutOfStock(props: {
  wrapper: ProgramWrapper;
}) {
  const [isDisplayingListLevel1, setIsDisplayingListLevel1] =
    createSignal(true);
  const [isDisplayingListLevel2, setIsDisplayingListLevel2] =
    createSignal(true);

  return (
    <>
      <section
        class="programs-nearby is-out-of-stock"
        data-ga-zone="other-programs"
        data-test="programs-nearby"
      >
        <div class="content-part">
          <p class="h3">
            Nos autres résidences{" "}
            {props.wrapper.program.other_programs_geo_level_1_where?.prefix}
            <strong>
              {props.wrapper.program.other_programs_geo_level_1_where?.name}
            </strong>
          </p>
          <div class="switch">
            <button
              type="button"
              class="btn"
              classList={{ active: isDisplayingListLevel1() }}
              onClick={() => setIsDisplayingListLevel1(true)}
            >
              Liste
            </button>
            <button
              type="button"
              class="btn"
              classList={{ active: !isDisplayingListLevel1() }}
              onClick={() => {
                setIsDisplayingListLevel1(false);
                setIsDisplayingListLevel2(true);
              }}
            >
              Carte
            </button>
          </div>
        </div>

        <div class="programs-list" data-ga-zone="list">
          {/* List */}
          <Show when={isDisplayingListLevel1()}>
            <ProjectErrorBoundary>
              <ProgramsNearbyList
                nearbyPrograms={
                  props.wrapper.program.other_programs_geo_level_1
                }
              />
            </ProjectErrorBoundary>
          </Show>

          {/* Map */}
          <Show when={!isDisplayingListLevel1()}>
            <Suspense>
              <ProgramsNearbyMap
                currentProgram={props.wrapper.program}
                nearbyPrograms={
                  props.wrapper.program.other_programs_geo_level_1
                }
              />
            </Suspense>
          </Show>
        </div>

        <Show
          when={props.wrapper.program.other_programs_geo_level_2.length > 0}
        >
          <div class="content-part">
            <p class="h3">
              Nos autres résidences{" "}
              {props.wrapper.program.other_programs_geo_level_2_where?.prefix}
              <strong>
                {props.wrapper.program.other_programs_geo_level_2_where?.name}
              </strong>
            </p>
            <div class="switch">
              <button
                type="button"
                class="btn"
                classList={{ active: isDisplayingListLevel2() }}
                onClick={() => setIsDisplayingListLevel2(true)}
              >
                Liste
              </button>
              <button
                type="button"
                class="btn"
                classList={{ active: !isDisplayingListLevel2() }}
                onClick={() => {
                  setIsDisplayingListLevel2(false);
                  setIsDisplayingListLevel1(true);
                }}
              >
                Carte
              </button>
            </div>
          </div>

          <div class="programs-list" data-ga-zone="list">
            {/* List */}
            <Show when={isDisplayingListLevel2()}>
              <ProjectErrorBoundary>
                <ProgramsNearbyList
                  nearbyPrograms={
                    props.wrapper.program.other_programs_geo_level_2
                  }
                />
              </ProjectErrorBoundary>
            </Show>

            {/* Map */}
            <Show when={!isDisplayingListLevel2()}>
              <Suspense>
                <ProgramsNearbyMap
                  currentProgram={props.wrapper.program}
                  nearbyPrograms={
                    props.wrapper.program.other_programs_geo_level_2
                  }
                />
              </Suspense>
            </Show>
          </div>
        </Show>

        <Show when={props.wrapper.program.other_cities.length > 0}>
          <OtherCities
            cities={props.wrapper.program.other_cities}
            displayAlone={true}
          />
        </Show>
      </section>
    </>
  );
}
