import type { ProgramWrapper } from "~/utils/program_wrapper";

import IconNotifications from "~/img/icons/notifications.svg";
import IconCampaign from "~/img/icons/campaign.svg";
import IconGroup from "~/img/icons/group.svg";
import IconConstruction from "~/img/icons/construction.svg";
import IconHomeWork from "~/img/icons/home_work.svg";
import IconKey from "~/img/icons/key.svg";

import "./SalesState.css";

type SalesStateProps = {
  wrapper: ProgramWrapper;
};

export default function SalesState(props: SalesStateProps) {
  return (
    <>
      <section class="sales-state-block" data-test="block-sales-state">
        <h2>Avancement du projet</h2>
        <div class="states">
          {/* Avant-première */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name ===
                "Avant-première",
              passed:
                props.wrapper.program.field_sales_state?.name ===
                  "En lancement" ||
                props.wrapper.program.field_sales_state?.name ===
                  "En commercialisation" ||
                props.wrapper.program.field_sales_state?.name ===
                  "En travaux" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Livraison imminente" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Prêt à emménager",
            }}
            data-test="state-preview"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconNotifications />
            </i>
            <span class="legend">Avant-première</span>
          </div>

          {/* En lancement */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name ===
                "En lancement",
              passed:
                props.wrapper.program.field_sales_state?.name ===
                  "En commercialisation" ||
                props.wrapper.program.field_sales_state?.name ===
                  "En travaux" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Livraison imminente" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Prêt à emménager",
            }}
            data-test="state-launching"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconCampaign />
            </i>
            <span class="legend">En lancement</span>
          </div>

          {/* En commercialisation */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name ===
                "En commercialisation",
              passed:
                props.wrapper.program.field_sales_state?.name ===
                  "En travaux" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Livraison imminente" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Prêt à emménager",
            }}
            data-test="state-commercialization"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconGroup />
            </i>
            <span class="legend">En commercialisation</span>
          </div>

          {/* En travaux */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name === "En travaux",
              passed:
                props.wrapper.program.field_sales_state?.name ===
                  "Livraison imminente" ||
                props.wrapper.program.field_sales_state?.name ===
                  "Prêt à emménager",
            }}
            data-test="state-build"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconConstruction />
            </i>
            <span class="legend">En travaux</span>
          </div>

          {/* Livraison imminente */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name ===
                "Livraison imminente",
              passed:
                props.wrapper.program.field_sales_state?.name ===
                "Prêt à emménager",
            }}
            data-test="state-delivery"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconHomeWork />
            </i>
            <span class="legend">Livraison imminente</span>
          </div>

          {/* Prêt à emménager */}
          <div
            class="state"
            classList={{
              active:
                props.wrapper.program.field_sales_state?.name ===
                "Prêt à emménager",
            }}
            data-test="state-ready"
          >
            <i aria-hidden="true" class="cog-icon">
              <IconKey />
            </i>
            <span class="legend">Prêt à emménager</span>
          </div>
        </div>
      </section>
    </>
  );
}
