import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useEventsContext } from "~/contexts/EventsContext";

import "./Cogehome3D.css";

import type { ProgramWrapper } from "~/utils/program_wrapper";

type Cogehome3DProps = {
  wrapper: ProgramWrapper;
};

export default function Cogehome3D(props: Cogehome3DProps) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section
        class="cogehome3d"
        id="maquette-3d"
        data-ga-zone="3d-block"
        data-test="block-cogehome3d"
      >
        <picture>
          <source
            media="(min-width: 375px)"
            srcset={imgProxy(
              "/images/cogehome3d-pers.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <img
            src={imgProxy(
              "/images/cogehome3d-pers.jpg",
              `size:327:184/resizing_type:fill`,
            )}
            alt=""
            height="184"
            width="327"
            loading="lazy"
          />
        </picture>
        <div class="tour-content">
          <div class="decoration">
            <img src="/images/pictos/cogehome3d-picto.svg" alt="Cogehome 3D" />
          </div>
          <span class="new">Nouveau</span>
          <h2>
            Découvrez les visites immersives de toutes nos résidences, logements
            avec <strong>CogeHome 3D</strong>
          </h2>
          <ul>
            <li>Explorez nos résidences et domaines à 360°</li>
            <li>Visitez en 3D chacun de nos logements meublés</li>
            <li>Découvrez les vues réelles de votre futur lieu de vie</li>
            <li>Configurez votre intérieur selon vos goûts</li>
          </ul>
          <button
            type="button"
            class="btn"
            onClick={() => {
              sendClick("btn-cogehome3d", "3d-block");
              setMediaOverlay("3ds", props.wrapper.program);
            }}
            data-test="btn-3d"
          >
            Commencer la visite 3D
          </button>
        </div>
      </section>
    </>
  );
}
