import { createSignal, onCleanup, onMount, Show } from "solid-js";
import * as helper from "~/utils/helper_program";
import { isServer } from "solid-js/web";
import { useEventsContext } from "~/contexts/EventsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

type AnchorProps = {
  wrapper: ProgramWrapper;
  withZoneAndTest?: boolean;
};

function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return (
    (window.innerHeight || document.documentElement.clientHeight) - rect.top >=
    (window.innerHeight || document.documentElement.clientHeight) - 110
  );
}

export default function Anchors(props: AnchorProps) {
  const [, { sendClick }] = useEventsContext();

  const [isAnchorPresentationActive, setIsAnchorPresentationActive] =
    createSignal(true);
  const [isAnchorPricesActive, setIsAnchorPricesActive] = createSignal(false);
  const [isAnchor3dActive, setIsAnchor3dActive] = createSignal(false);
  const [isAnchorSalesOfficeActive, setIsAnchorSalesOfficeActive] =
    createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    const elementPresentation = document.getElementById("presentation");
    if (elementPresentation && isInViewport(elementPresentation)) {
      setIsAnchorPresentationActive(true);
      setIsAnchorPricesActive(false);
      setIsAnchor3dActive(false);
      setIsAnchorSalesOfficeActive(false);
    }
    const elementPrice = document.getElementById("prix-plans");
    if (elementPrice && isInViewport(elementPrice)) {
      setIsAnchorPresentationActive(false);
      setIsAnchorPricesActive(true);
      setIsAnchor3dActive(false);
      setIsAnchorSalesOfficeActive(false);
    }
    const element3d = document.getElementById("maquette-3d");
    if (element3d && isInViewport(element3d)) {
      setIsAnchorPresentationActive(false);
      setIsAnchorPricesActive(false);
      setIsAnchor3dActive(true);
      setIsAnchorSalesOfficeActive(false);
    }
    const elementSalesOffice = document.getElementById("espace-de-vente");
    if (elementSalesOffice && isInViewport(elementSalesOffice)) {
      setIsAnchorPresentationActive(false);
      setIsAnchorPricesActive(false);
      setIsAnchor3dActive(false);
      setIsAnchorSalesOfficeActive(true);
    }
  };

  return (
    <>
      <div
        class="anchors"
        data-ga-zone={props.withZoneAndTest ? "anchors" : null}
        data-test="anchors"
      >
        <a
          href="#presentation"
          class="btn"
          data-test="anchor-presentation"
          classList={{ active: isAnchorPresentationActive() }}
          onClick={() => sendClick("anchor-presentation", "anchors")}
        >
          Présentation
        </a>
        <Show when={!helper.isPreview(props.wrapper)}>
          <a
            href="#prix-plans"
            class="btn"
            data-test="anchor-prices"
            classList={{ active: isAnchorPricesActive() }}
            onClick={() => sendClick("anchor-grid", "anchors")}
          >
            Prix & plans
          </a>
        </Show>
        <Show when={helper.virtualVisitsLength(props.wrapper) > 0}>
          <a
            href="#maquette-3d"
            class="btn"
            data-test="anchor-3d"
            classList={{ active: isAnchor3dActive() }}
            onClick={() => sendClick("anchor-3d", "anchors")}
          >
            Maquette 3D
          </a>
        </Show>
        <Show
          when={
            props.wrapper.program.field_so_block_closed ||
            props.wrapper.program.field_so_block_visio ||
            props.wrapper.program.field_so_block_std
          }
        >
          <a
            href="#espace-de-vente"
            class="btn"
            data-test="anchor-so"
            classList={{ active: isAnchorSalesOfficeActive() }}
            onClick={() => sendClick("anchor-sales-office", "anchors")}
          >
            Espace de vente
          </a>
        </Show>
      </div>
    </>
  );
}
