import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useEventsContext } from "~/contexts/EventsContext";

import "./Block3D.css";

import type { ProgramWrapper } from "~/utils/program_wrapper";

type Block3DProps = {
  wrapper: ProgramWrapper;
};

export default function Block3D(props: Block3DProps) {
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section
        class="tour-block"
        id="maquette-3d"
        data-ga-zone="3d-block"
        data-test="block-3d"
      >
        <picture>
          <source
            media="(min-width: 375px)"
            srcset={imgProxy(
              "/images/block3d-visual.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <img
            src={imgProxy(
              "/images/block3d-visual.jpg",
              `size:327:184/resizing_type:fill`,
            )}
            alt=""
            height="184"
            width="327"
            loading="lazy"
          />
        </picture>
        <div class="tour-content">
          <h2>Projetez vous dans votre futur logement en 3D</h2>
          <p>
            Explorez la résidence à 360° et découvrez l’agencement des
            appartements disponibles.
          </p>
          <button
            type="button"
            class="btn"
            onClick={() => {
              sendClick("btn-3d", "3d-block");
              setMediaOverlay("3ds", props.wrapper.program);
            }}
            data-test="btn-3d"
          >
            Voir la maquette 3D
          </button>
        </div>
      </section>
    </>
  );
}
