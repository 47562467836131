import { For } from "solid-js";

import "./OtherCities.css";
import { urlRs } from "~/utils/url";

export default function OtherCities(props: {
  cities: { name: string; url: string }[];
  displayAlone: boolean;
}) {
  return (
    <>
      <div
        class="other-cities"
        classList={{ "display-alone": props.displayAlone }}
        data-test="other-cities"
      >
        <h3>Villes à proximité</h3>
        <ul class="cities">
          <For each={props.cities}>
            {(city) => (
              <li data-test="city">
                <a href={urlRs("geographies", city.url)} hreflang="fr">
                  {city.name}
                </a>
              </li>
            )}
          </For>
        </ul>
      </div>
    </>
  );
}
