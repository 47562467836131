import { createMemo, Match, Show, Switch } from "solid-js";
import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { hasNoEscape } from "~/utils/no_escape";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import IconDomain from "~/img/icons/domain.svg";
import IconLocationOn from "~/img/icons/location_on.svg";
import IconToday from "~/img/icons/today.svg";
import IconFace from "~/img/icons/face.svg";
import IconCall from "~/img/icons/call.svg";
import IconForum from "~/img/icons/forum.svg";

import "./SalesOffice.css";
import { useModalFormContext } from "~/contexts/ModalFormContext";

type SalesOfficeProps = {
  wrapper: ProgramWrapper;
};

export default function SalesOffice(props: SalesOfficeProps) {
  return (
    <>
      <section
        class="sales-office-visio-row"
        id="espace-de-vente"
        data-test="contacts"
      >
        <Show
          when={
            (props.wrapper.program.field_so_block_closed &&
              !props.wrapper.program.field_so_block_std) ||
            (!props.wrapper.program.field_so_block_closed &&
              props.wrapper.program.field_so_block_std &&
              props.wrapper.program.field_so_city)
          }
        >
          <SalesOfficeBlock wrapper={props.wrapper} />
        </Show>
        <Show
          when={props.wrapper.program.field_so_block_visio && !hasNoEscape()}
        >
          <SalesOfficeVisioBlock wrapper={props.wrapper} />
        </Show>
      </section>
    </>
  );
}

function SalesOfficeBlock(props: SalesOfficeProps) {
  const settingsContext = useDrupalSettingsContext();
  const [, { setTempZone }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  const cardDataTest = createMemo(() => {
    if (
      props.wrapper.program.field_so_block_closed &&
      !props.wrapper.program.field_so_block_std
    ) {
      return "card-sales-office-closed";
    }
    return "card-sales-office";
  });

  return (
    <>
      <section
        class="sales-office-block"
        data-ga-zone="sales-office"
        data-test={cardDataTest()}
      >
        <picture>
          <source
            media="(min-width: 375px)"
            srcset={imgProxy(
              "/images/sales-office-bg.jpg",
              "size:768:421/resizing_type:fill",
            )}
          />
          <img
            src={imgProxy(
              "/images/sales-office-bg.jpg",
              `size:327:180/quality:90/resizing_type:fill`,
            )}
            alt=""
            height="180"
            width="327"
            loading="lazy"
          />
        </picture>
        <div class="block-content">
          <Switch>
            <Match
              when={
                props.wrapper.program.field_so_block_closed &&
                !props.wrapper.program.field_so_block_std
              }
            >
              <div class="infos">
                <h2>
                  <i aria-hidden="true" class="cog-icon">
                    <IconDomain />
                  </i>
                  Nous rencontrer
                </h2>
                <p>Notre espace de vente est actuellement fermé.</p>
                <p>
                  <strong>
                    Nos conseillers restent cependant disponibles par téléphone,
                    mail ou visioconférence.
                  </strong>
                </p>
              </div>
              <div class="buttons">
                <button
                  type="button"
                  class="btn button-closed"
                  data-test="cta-advisor"
                  onClick={(e) => {
                    setTempZone(e);
                    openModalForm!("advisor", {
                      wrapper: props.wrapper,
                    });
                    setModalFormHeadline!("Contacter un conseiller");
                  }}
                >
                  Contacter un conseiller
                </button>
              </div>
            </Match>
            <Match
              when={
                !props.wrapper.program.field_so_block_closed &&
                props.wrapper.program.field_so_block_std &&
                props.wrapper.program.field_so_city
              }
            >
              <div class="infos">
                <h2>
                  <i aria-hidden="true" class="cog-icon">
                    <IconDomain />
                  </i>
                  Nous rencontrer
                </h2>
                <address>
                  <Show when={props.wrapper.program.field_so_street}>
                    {props.wrapper.program.field_so_street}
                    <br />
                  </Show>
                  <Show when={props.wrapper.program.field_so_additional}>
                    {props.wrapper.program.field_so_additional}
                    <br />
                  </Show>
                  <Show when={props.wrapper.program.field_so_postal_code}>
                    {props.wrapper.program.field_so_postal_code}{" "}
                  </Show>
                  <Show when={props.wrapper.program.field_so_city}>
                    {props.wrapper.program.field_so_city}
                  </Show>
                </address>
                <Show when={props.wrapper.program.field_so_opening}>
                  <p>{props.wrapper.program.field_so_opening}</p>
                </Show>
              </div>
              <div class="buttons">
                <Show
                  when={settingsContext.cityscan_is_enabled && !hasNoEscape()}
                >
                  <button
                    type="button"
                    class="btn"
                    data-test="btn-map"
                    onClick={() => {
                      sendClick("btn-map", "sales-office");
                      setMediaOverlay(
                        "embed",
                        `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
                      );
                    }}
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconLocationOn />
                    </i>
                    Voir sur la carte
                  </button>
                </Show>
                <button
                  type="button"
                  class="btn"
                  data-test="cta-advisor-appointment"
                  onClick={(e) => {
                    setTempZone(e);
                    openModalForm!("advisor", {
                      wrapper: props.wrapper,
                      defaultValues: { subject: "rdv" },
                    });
                    setModalFormHeadline!("Prendre un rendez-vous");
                  }}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconToday />
                  </i>
                  Prendre un rendez-vous
                </button>
              </div>
            </Match>
          </Switch>
        </div>
      </section>
    </>
  );
}

export function SalesOfficeVisioBlock(props: { wrapper: ProgramWrapper }) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  return (
    <>
      <section
        class="sales-office-block"
        data-ga-zone="sales-office"
        data-test="card-contact"
      >
        <picture>
          <source
            media="(min-width: 375px)"
            srcset={imgProxy(
              "/images/sales-office-visio-bg.jpg",
              "size:768:421/resizing_type:fill",
            )}
          />
          <img
            src={imgProxy(
              "/images/sales-office-visio-bg.jpg",
              `size:327:180/quality:90/resizing_type:fill`,
            )}
            alt=""
            height="180"
            width="327"
            loading="lazy"
          />
        </picture>
        <div class="block-content">
          <h2>
            <i aria-hidden="true" class="cog-icon">
              <IconFace />
            </i>
            Nous contacter
          </h2>
          <p>
            Tous nos conseillers sont disponibles pour vous accompagner dans
            votre projet et répondre à toutes vos questions, lors d’un
            rendez-vous téléphonique, en visio-conférence ou sur l’un de nos
            espaces de vente.
          </p>
          <div class="buttons">
            <button
              type="button"
              class="btn"
              data-test="cta-callback"
              onClick={(e) => {
                sendShowEvent(`callback`, e, {
                  nid: props.wrapper.program.drupal_internal__nid,
                });
                openModalForm!("callback", {
                  wrapper: props.wrapper,
                });
                setModalFormHeadline!("Être appelé");
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconCall />
              </i>
              Être appelé
            </button>
            <button
              type="button"
              class="btn"
              data-test="cta-advisor-question"
              onClick={(e) => {
                sendShowEvent("contact-us", e, {
                  nid: props.wrapper.program.drupal_internal__nid,
                });
                openModalForm!("advisor", {
                  wrapper: props.wrapper,
                  defaultValues: { subject: "question" },
                });
                setModalFormHeadline!("Poser une question");
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconForum />
              </i>
              Poser une question
            </button>
            <button
              type="button"
              class="btn"
              data-test="cta-advisor-appointment"
              onClick={(e) => {
                sendShowEvent("advisor", e, {
                  nid: props.wrapper.program.drupal_internal__nid,
                });
                openModalForm!("advisor", {
                  wrapper: props.wrapper,
                  defaultValues: { subject: "rdv" },
                });
                setModalFormHeadline!("Prendre un rendez-vous");
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconToday />
              </i>
              Prendre un rendez-vous
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
